<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit">
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>إضافة نشاط</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col cols="12">
            <BTextInputWithValidation
              rules="required"
              label="اسم النشاط"
              name="اسم النشاط"
              v-model="activitesDto.title"
              placeholder="أدخل اسم النشاط"
            />
            <BDatepickerWithValidation
              rules="required"
              label="تاريخ النشاط "
              name="تاريخ النشاط"
              v-model="activitesDto.date"
              placeholder="أدخل تاريخ النشاط"
            />
            <label>صورة</label>
            <fileDragDrop
              @inValidFile="unValidThumbImage"
              @uploaded="uploadThumbImage"
              id="employee-photo"
              height="100px"
              type="image"
              title="صورة جديدة"
            />
            <img
              v-if="!!activitesDto.base64"
              :src="activitesDto.base64"
              style="max-height: 320px; object-fit: fill;"
              class="w-100"
            />
            <small class="text-danger">
              {{ !this.activitesDto.base64 ? "صورة النشاط مطلوبة" : "" }}
            </small>
            <div class="editor-container mt-2">
              <quill-editor
                class="editor"
                ref="myTextEditor"
                :value="activitesDto.description"
                :options="editorOption"
                @change="onEditorChange"
              />
            </div>
            <small class="text-danger mt-5" v-show="!activitesDto.description"
              >شرح النشاط مطلوب</small
            >
          </b-col>
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button
                variant="outline-primary"
                class="ml-auto mr-1"
                @click="is = false"
                >إلغاء</b-button
              >
              <b-button variant="primary" type="submit">تم</b-button>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
  import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
  import { ValidationObserver } from "vee-validate";
  import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
  import BDatepickerWithValidation from "@core/components/inputs/BDatepickerWithValidation";
  import { mapActions, mapGetters } from "vuex";
  import { quillEditor } from "vue-quill-editor";
  import hljs from "highlight.js";
  export default {
    components: {
      quillEditor,
      BTextInputWithValidation,
      ValidationObserver,
      fileDragDrop,
      BDatepickerWithValidation,
    },
    data: () => ({
      editorOption: {
        modules: {
              toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['blockquote', 'code-block'],
                  [{ 'header': 1 }, { 'header': 2 }],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],
                  [{ 'indent': '-1' }, { 'indent': '+1' }],
                  [{ 'direction': 'rtl' }],
                  [{ 'size': ['small', false, 'large', 'huge'] }],
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'font': [] }],
                  [{ 'color': [] }, { 'background': [] }],
                  [{ 'align': [] }],
                  ['clean'],
                  ['link', 'image', 'video']
              ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
        placeholder: "ادخل شرح عن المرحلة",
      },
      activitesDto: {
        title: "",
        base64: "",
        date: "",
        description: "",
      },
    }),
    computed: {
      ...mapGetters({ activitesDetails: "activitesDetails", lang: "app/lang" }),
      is: {
        get() {
          return this.$store.getters["isCreateActiviteModal"];
        },
        set(is) {
          this.$store.dispatch("setIsCreateActiviteModal", is);
        },
      },
    },
    methods: {
      resetForm() {
        Object.assign(this.activitesDto, {
          title: "",
          base64: "",
          date: "",
          description: "",
        });
        Object.assign(this.activitesDetails, {
          date: "",
          id: "",
          title: "",
          description: "",
          language: this.lang,
          imageDto: {
            id: 0,
            path: "",
            base64: "",
          },
        });
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
        });
      },
      uploadThumbImage(file) {
          this.activitesDto.base64 = file[0].base64;
      },
      unValidThumbImage() {
        console.log("un valid Thumb file");
      },
      onEditorChange(value) {
        this.activitesDto.description = value.html;
      },
      ...mapActions(["setNewActivities"]),

      onSubmit() {
        this.$refs.observer.validate().then((success) => {
          if (success && this.activitesDto.base64 && this.activitesDto.description) {
            this.setNewActivities({
              id: 0,
              title: this.activitesDto.title,
              description: this.activitesDto.description,
              date: new Date(this.activitesDto.date),
              language: this.lang,
              imageDto: {
                id: 0,
                path: null,
                base64: this.activitesDto.base64
              }
            });
            this.resetForm();
          }
        });
      },
    },
  };
</script>
